import './App.css';
import QrReader from 'react-qr-reader'
import {useEffect, useState} from "react";
import {
    Card,
    Container,
    createMuiTheme,
    makeStyles, Paper,
    Step,
    StepLabel,
    Stepper, Table, TableBody, TableCell, TableContainer, TableRow,
    ThemeProvider,
    Typography
} from "@material-ui/core";

const cbor = require("cbor-web");

const base45 = require("base45-js");
const pako = require("pako");

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: "#f1f1f1",
        color: "black",
        padding: "20px",
        minHeight: window.innerHeight

    },
    title: {
        marginRight: theme.spacing(2),
    },

    steppers: {
        background: "transparent"
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#3f51b5',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#ffbe00',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffffff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

function App() {
    const classes = useStyles();
    const mock = ""
    var [state, setState] = useState(mock)
    var [error, setError] = useState(false)
    const steps = ["Scan", "Analyze"]

  function handleScan(data) {
    if (data) {
      setState(data);
    }
  };

  function handleError(err) {
    console.error(err);
    setError(true);
  }

  return (
    <div className="App">
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
            <Typography variant="h3">GreenPass Decoder</Typography>
            <Typography>Discover what is put inside your QR Code !</Typography>
            <Typography/>
            <div style={{marginTop: '20px'}}/>
            <Typography variant="caption" align="left">This website doesn't use cookies or trackers. Data is processed on your device, we don't collect any data.</Typography>
            <Typography variant="caption">This website is open source, code is available on  <a href="https://gitlab.com/fjolain/greenpass-decoder">gitlab.com</a> under Apache 2 License</Typography>

            <Stepper className={classes.steppers} activeStep={state === "" ? 0 : 1} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {error ? <Typography style={{color: "red"}}>We can't start scanning. Do you autorize camera running ?</Typography>: <div/>}
            {state === "" ?
                <Card><QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                /></Card>
                :
                <Viewer data={state}/>
            }
        </Container>

            </ThemeProvider>
        </div>
    </div>
  );
}

export default App;

export function Viewer({data}) {
    const [items, setItems] = useState([]);

    async function decode(data) {
        let inflated = base45.decode(data.substring(4));

        inflated = pako.inflate(inflated)

        const obj = await cbor.decodeFirst(inflated);
        const plaintext = obj.value[2];

        const content = cbor.decode(plaintext);
        return extract(content.get(-260).get(1));
    }


    function extract(data) {
        let array = [
            {key: "Name", value: data.nam.gn + " " + data.nam.fn},
            {key: "date of born", value: data.dob}
        ]

        if (data.v !== undefined) {
            const vacc = data.v[0];
            array = array.concat([
                {key: "shot", value: vacc.dn + "/" + vacc.sd},
                {key: "date of shot", value: vacc.dt},
                {key: "manufacturer", value: getManf(vacc.ma)}
            ])
        }

        if (data.t !== undefined) {
            const test = data.t[0];
            array = array.concat([
                {key: "date of test", value: test.sc},
                {key: "result of test", value: test.tr === "260415000" ? "no detected" : "detected"},
                {key: "type of test", value: getTestType(test.tt)}
            ])
        }

        return array
    }

    function getTestType(code) {
        switch (code) {
            case "LP6464-4":
                return "NAAT"
            case "LP217198-3":
                return "Rapid"
            default:
                return "Unknown"
        }
    }

    function getManf(code) {
        switch (code) {
            case "ORG-100001699":
                return "AstraZeneca"
            case "ORG-100030215":
                return "Pfizer/BioNTech"
            case "ORG-100031184":
                return "Moderna"
            default:
                return "Unknown"
        }
    }

    useEffect(() => {
        decode(data).then((res) => {
            setItems(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    return (<TableContainer component={Paper}>
        <Table size="small">
            <TableBody>
                {items.map((item) => (
                    <TableRow key={item.key}>
                        <TableCell component="th" scope="row" align="right"><b>{item.key}</b></TableCell>
                        <TableCell align="left">{item.value}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>)

}
